import React from "react"

const SearchResults = ({ query, results }) => (
  <section aria-label="Search results for all posts">
    <div class="bg-white pb-20 px-4 sm:px-6 lg:pb-28 lg:px-8 pt-12">
    <div class="relative max-w-lg mx-auto lg:max-w-7xl">
    {!!results.length && query && (
      <div>
      <h2
        className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10"
        id="search-results-count"
        aria-live="assertive"
      >
        Found {results.length} posts for "{query}"
      </h2>
      <p class="mt-3 text-xl leading-7 text-gray-500 sm:mt-4">
        Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.
      </p>
      </div>
    )}
    {!!results.length && (
        
      <div className="mt-12 grid gap-16 border-t-2 border-gray-100 pt-12">
        {results.map(({ title, slug, category, excerpt }) => (


        <div>
        <div>
          <div className="inline-block">
            <span className="inline-flex items-center px-3 py-0.5 rounded text-sm font-medium leading-5 bg-indigo-100 text-indigo-800 capitalize">
              {category}
            </span>
          </div>
        </div>
        <a href={slug} className="block">
          <h3 className="mt-4 text-xl leading-7 font-semibold text-gray-900">
            {title}
          </h3>
          <p className="mt-3 text-base leading-6 text-gray-500">
            Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.
          </p>
        </a>
      </div>

        ))}
      </div>
    )}
    </div>
    </div>
  </section>
)

export default SearchResults