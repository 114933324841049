import React, { Component, useState, useEffect } from "react";
import debounce from "lodash.debounce"
import Helmet from "react-helmet";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import SearchResults from "../components/Search/SearchResults";

const Search = ({ data, location }) => {
  const [results, setResults] = useState([])
  const searchQuery = new URLSearchParams(location.search).get("keywords") || ""

  useEffect(() => {
    if (searchQuery && window.__LUNR__) {
      const debouncedSearch = debounce(async () => {
        const lunr = await window.__LUNR__.__loaded
        const refs = lunr.en.index.search(searchQuery)
        const posts = refs.map(({ ref }) => lunr.en.store[ref])

        setResults(posts)
      }, 500)

      debouncedSearch()
    }

    if (!searchQuery) setResults([])
  }, [location.search])

  return (
    <Layout>
        <div className="search-container">
          <Helmet title={`${results.length} search results for ${searchQuery} at ${config.siteTitle}`} />        
            <SearchResults query={searchQuery} results={results} />
        </div>
    </Layout>
  )
}

export default Search


